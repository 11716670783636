import axios from 'axios'

import { router } from '../main'
import { WebSocketHandler } from '../util/websocket'

const sessionId = Math.random().toString(36).slice(2)

const baseApi = axios.create({
  // baseURL: 'https://partner-api.foodtosave.com.br/api'
  // baseURL: "https://partner-test.foodtosave.com.br/api",
  // baseURL: 'http://localhost:8080/api'
  // baseURL: "http://partner-api.sandbox.foodtosave.com/api"
  baseURL: process.env.VUE_APP_API_URL || 'https://partner-api.foodtosave.com.br/api'
})

baseApi.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem('access_token')
    if (
      !window.location.pathname?.includes('login') &&
      window.location.pathname != '/' &&
      accessToken == null
    ) {
      resetSession()
      return
    }
    const accessTokenHeader = `Bearer ${accessToken}`
    config.headers = {
      'X-Session-Id': sessionId,
      'X-Trace-Id': Math.random().toString(36).slice(2),
      Authorization: accessTokenHeader
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

baseApi.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error?.response.status == 401 || error?.response.status == 403) {
      resetSession()
      return
    }
    if (error?.response.status === 400) {
      error.response.data =
        'Houve um erro ao processar a requisição. Por favor, entre em contato com nosso suporte'
    }
    return Promise.reject(error)
  }
)

export function resetSession() {
  try {
    const wsHandler = new WebSocketHandler()
    wsHandler.disconnectWebsocket()
  } finally {
    router.push({ name: 'login' })
    sessionStorage.clear()
    window.location.reload()
  }
}

export async function refreshToken() {
  try {
    const access_token = sessionStorage.getItem('access_token')
    const refreshToken = sessionStorage.getItem('refresh_token')
    const header = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`
    }
    const parameters = {
      method: 'POST',
      headers: header
    }
    const body = {
      refresh_token: refreshToken
    }
    if (access_token == null || refreshToken == null) {
      resetSession()
      return
    }
    baseApi
      .post('/v1/authentication/refresh-token', body, parameters)
      .then(async (res) => {
        sessionStorage.setItem('access_token', res.data.access_token)
        sessionStorage.setItem('refresh_token', res.data.refresh_token)
      })
      .catch(() => {
        resetSession()
      })
  } catch (err) {
    resetSession()
  }
}

export function setRefreshTokenTimer() {
  const refreshTokenTokenExpiresInMilliseconds = 60 * 60 * 1000
  const fiveMinutesInMilliseconds = 5 * 60 * 1000
  const refreshTokenIsCalled =
    refreshTokenTokenExpiresInMilliseconds - fiveMinutesInMilliseconds
  setInterval(refreshToken, refreshTokenIsCalled)
  if (sessionStorage.getItem('refresh_token')) {
    refreshToken()
  }
}

if (
  !window.location.pathname?.includes('login') &&
  window.location.pathname != '/'
) {
  setRefreshTokenTimer()
}

export default baseApi
