<template>
  <PageContent>
    <v-container fluid class="flex-grow-1 content">
      <v-row>
        <v-col md="6" cols="12">
          <v-progress-circular
            v-if="!estabelecimento"
            indeterminate
            color="primary"
          />

          <v-row>
            <v-col cols="3" sm="2" md="2" xl="1" class="pr-0">
              <p class="sections-title">Vendas</p>
            </v-col>
            <v-col
              cols="9"
              sm="10"
              md="10"
              xl="11"
              class="pl-0"
              style="display: flex; align-items: center"
            >
            </v-col>
          </v-row>
          <statistics-view
            v-if="estabelecimento"
            :partner_id="estabelecimento.id"
          />

          <p class="sections-title mb-0">Sacolas Surpresa disponíveis</p>

          <v-row>
            <v-col md="12" dense>
              <gondola-section
                ref="gondolaSection"
                @addGlobalError="addGlobalError"
                @clearGlobalErrors="clearGlobalErrors"
              />
            </v-col>
          </v-row>
          <div class="invoices-container-hide">
            <p class="sections-title">Faturas e notas fiscais</p>
            <invoices-table :estabelecimento="estabelecimento" />
          </div>
          <v-row class="invoices-container-bottom-hide">
            <v-col>
              <p class="sections-title">Faturas e notas fiscais</p>
              <invoices-table :estabelecimento="estabelecimento" />
            </v-col>
          </v-row>
          <v-row
            v-if="!this.hiddenKraftBagRequestIsEnabled"
            class="kraft-bag-requests"
          >
            <v-col>
              <p class="sections-title">Solicite Sacola Kraft</p>
              <kraf-bag-request-form :partner="estabelecimento" />
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" cols="12">
          <section v-if="pedidos" style="min-height: 100%">
            <p class="sections-title">Pedidos</p>
            <orders-table
              ref="ordersTable"
              :estabelecimento="estabelecimento"
              @getGondolasEvent="getGondolasEvent"
              :analyticsEventLabels="{
                ORDER_CANCELED: 'order_canceled',
                OPEN_ORDER_DETAILS: null,
                ORDER_SEARCH: 'order_search',
                ORDER_DATE_FILTER: null,
                ORDER_STATUS_FILTER: null,
                ORDER_DOWNLOAD: null
              }"
            />
          </section>
        </v-col>
      </v-row>
    </v-container>
  </PageContent>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import 'vue2-datepicker/index.css'
import { formatLocaleDate, toDinheiro, toTelefone } from '@/util/Formater.js'
import OrdersTable from '@/components/order/OrdersTable.vue'
import InvoicesTable from '@/components/invoices/InvoicesTable.vue'
import StatisticsView from '@/components/stats/StatisticsView.vue'
import KrafBagRequestForm from '../components/kraftbag/KrafBagRequestForm.vue'
import { partnerConfigurationModelType } from '@/util/constants'
import GondolaSection from '@/components/bag/GondolaSection'
import { BlipChat } from 'blip-chat-widget'
import PageContent from '@/components/shared/PageContent.vue'
import { resetSession } from '@/plugins/axios'
import { setUsetifulTags } from 'usetiful-sdk'

export default {
  name: 'MainPage',
  components: {
    PageContent,
    OrdersTable,
    StatisticsView,
    KrafBagRequestForm,
    GondolaSection,
    InvoicesTable: InvoicesTable
  },
  computed: {
    ...mapState(['estabelecimento', 'loading'])
  },
  data() {
    return {
      user: null,
      pedido: null,
      pedidoId: null,
      method: 'Adicionar',
      pedidos: [],
      globalErrors: [],
      gondolasWithoutBagsMap: null,
      erros: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      screenSize: this.$vuetify.breakpoint.name
    }
  },

  created() {
    if (this.estabelecimento === null) {
      this.$router.push({ name: '' })
      return
    } else {
      const customPriceGondolaIsEnabled = Boolean(
        this.estabelecimento.configurations?.find(
          (config) =>
            config.name ===
            partnerConfigurationModelType.BAG_DYNAMIC_PRICE_MODEL
        )?.enabled
      )

      const contentGondolaBagIsEnabled = Boolean(
        this.estabelecimento.configurations?.find(
          (config) =>
            config.name === partnerConfigurationModelType.BAG_WITH_CONTENT_MODEL
        )?.enabled
      )

      const hiddenKraftBagRequestIsEnabled = Boolean(
        this.estabelecimento.configurations?.find(
          (config) =>
            config.name === partnerConfigurationModelType.HIDE_KRAFT_BAG_REQUEST
        )?.enabled
      )

      this.customPriceGondolaIsEnabled = customPriceGondolaIsEnabled
      this.contentGondolaBagEnabled = contentGondolaBagIsEnabled
      this.hiddenKraftBagRequestIsEnabled = hiddenKraftBagRequestIsEnabled

      setUsetifulTags({
        customPriceGondolaEnabled: this.customPriceGondolaIsEnabled.toString(),
        contentGondolaEnabled: this.contentGondolaBagEnabled.toString(),
        defaultGondolaEnabled: (
          !this.contentGondolaBagEnabled && !this.customPriceGondolaIsEnabled
        ).toString(),
        partnerName: this.estabelecimento.nome,
        partnerId: this.estabelecimento.id,
        userId: this.estabelecimento.email
      })
    }

    // Start Blip Chat Widget Script
    new BlipChat()
      .withAppKey(
        'Zm9vZHRvc2F2ZWIyYjo0ZDA4OGY5OC1mOTI2LTQxM2MtOGFlYi03Y2FkNmZhMDU4OGU='
      )
      .withButton({ color: '#FF6600', icon: '' })
      .withCustomCommonUrl('https://foodtosave.chat.blip.ai/')
      .withAuth({
        authType: BlipChat.DEV_AUTH,
        userIdentity: this.estabelecimento.email,
        userPassword: 'MTIzNDU2'
      })
      .withAccount({
        fullName: this.estabelecimento.nome,
        email: this.estabelecimento.email
      })
      .build()
  },
  mounted() {
    this.user = this.$store.getters.usuarioLogado
    if (!this.user) {
      resetSession()
      return
    }

    this.$store
      .dispatch('shouldRedirectToSelfSignUpOnboarding')
      .then((shouldRedirectToSelfSignUpOnboarding) => {
        if (shouldRedirectToSelfSignUpOnboarding) {
          this.$gtag.event('first_access_selfsignup_pending')

          return this.$router.push({
            name: 'self-sign-up-bag-management'
          })
        }
      })
  },
  methods: {
    ...mapMutations(['setEstabelecimento']),
    toDinheiro,
    toTelefone,

    showTutorialVideoDialog() {
      this.showTutorialVideo = true
    },
    closeTutorialVideoDialog() {
      try {
        const iframe = this.$refs.partnerAdminTutorial?.contentWindow
        iframe.postMessage(
          '{"event":"command","func":"stopVideo","args":""}',
          '*'
        )
      } catch (_) {
        // Catch possible errors due to possible calls of postMessage on unsuported browsers
      }
      this.showTutorialVideo = false
    },

    async getGondolasEvent() {
      await this.$refs.gondolaSection.reloadGondolasMap()
    },

    addGlobalError(event) {
      if (event) {
        this.globalErrors.push(event)
      }
    },
    clearGlobalErrors() {
      this.globalErrors = []
    },

    formatLocaleDate
  }
}
</script>

<style>
@import '../style/main_board.scss';
</style>
