import Vue from 'vue'
import Vuex from 'vuex'
import { selfSignUpModule } from './store/modules/selfSignUp'
import { parterSignUpOriginEnum, partnerStatusEnum } from '../util/constants'
import { setRefreshTokenTimer } from '@/plugins/axios'
import { router } from '../main'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    perguntaSessao: false,
    endereco: null,
    pedido: null,
    cliente: null,
    estabelecimento: null,
    pedidoConfirmado: null,
    usuarioLogado: null,
    lastTimeOrderArrivedSoundPlayed: null,
    gondolaBagErrors: new Map()
  },
  mutations: {
    loading(state, loading) {
      state.loading = loading
    },
    perguntaSessao(state, display) {
      state.perguntaSessao = display
    },
    endereco(state, endereco) {
      state.endereco = endereco
      localStorage.setItem('food_endereco', JSON.stringify(endereco))
    },
    pedido(state, pedido) {
      state.pedido = pedido
      sessionStorage.setItem('food_pedido', JSON.stringify(pedido))
    },
    cliente(state, cliente) {
      state.cliente = cliente
      sessionStorage.setItem('food_cliente', JSON.stringify(cliente))
    },
    lastTimeOrderArrivedSoundPlayed(state, lastTimeOrderArrivedSoundPlayed) {
      state.lastTimeOrderArrivedSoundPlayed = lastTimeOrderArrivedSoundPlayed
      sessionStorage.setItem('last_time_order_arrived_sound_played', lastTimeOrderArrivedSoundPlayed)
    },
    setEstabelecimento(state, estabelecimento) {
      state.estabelecimento = estabelecimento
      sessionStorage.setItem(
        'food_estabelecimento',
        JSON.stringify(estabelecimento)
      )
    },
    pedidoConfirmado(state, pedido) {
      state.pedidoConfirmado = pedido
      sessionStorage.setItem('food_pedido_confirmado', JSON.stringify(pedido))
    },
    usuarioLogado(state, usuario) {
      state.usuarioLogado = usuario
      sessionStorage.setItem('food_usuario', JSON.stringify(usuario))
    },
    logout(state) {
      state.usuarioLogado = null
      sessionStorage.setItem('food_usuario', null)
    },
    setGondolaBagErrors(state, gondolaErrorMap) {
      state.gondolaBagErrors = gondolaErrorMap
    },
    cleanGondolaBagErrors(state) {
      state.gondolaBagErrors = new Map()
    }
  },
  getters: {
    endereco: (state) => {
      if (state.endereco) {
        return state.endereco
      }
      return JSON.parse(localStorage.getItem('food_endereco'))
    },
    pedido: (state) => {
      if (state.pedido) {
        return state.pedido
      }
      return JSON.parse(sessionStorage.getItem('food_pedido'))
    },
    cliente: (state) => {
      if (state.cliente) {
        return state.cliente
      }
      return JSON.parse(sessionStorage.getItem('food_cliente'))
    },
    getEstabelecimento: (state) => {
      if (state.estabelecimento) {
        return state.estabelecimento
      }
      return JSON.parse(sessionStorage.getItem('food_estabelecimento'))
    },
    pedidoConfirmado: (state) => {
      if (state.pedidoConfirmado) {
        return state.pedidoConfirmado
      }
      return JSON.parse(sessionStorage.getItem('food_pedido_confirmado'))
    },
    usuarioLogado: (state) => {
      if (state.usuarioLogado) {
        return state.usuarioLogado
      }
      return JSON.parse(sessionStorage.getItem('food_usuario'))
    },
    getGondolaBagErrors: (state) => {
      return state.gondolaBagErrors
    },
    estabelecimentoTemCadastroPendente: (state) => {
      const estabelecimento = state.estabelecimento
      if (!estabelecimento) {
        return false
      }

      return estabelecimento.status === partnerStatusEnum.CONFIRMACAO
    },
    getLastTimeOrderArrivedPlayed: (state) => {
      return state.lastTimeOrderArrivedSoundPlayed
    }
  },
  actions: {
    signUpOnboardingAlreadyShownToUser({ state }) {
      const estabelecimento = state.estabelecimento
      if (!estabelecimento) {
        return false
      }

      try {
        const listOfAlreadyShownOnboarding =
          JSON.parse(localStorage.getItem('food_onboarding_shown')) || []
        return listOfAlreadyShownOnboarding.includes(estabelecimento.id)
      } catch (_) {
        return false
      }
    },
    async shouldRedirectToSelfSignUpOnboarding({ state, dispatch }) {
      const estabelecimento = state.estabelecimento

      if (
        !estabelecimento ||
        estabelecimento.status !== partnerStatusEnum.CONFIRMACAO ||
        estabelecimento.signup_origin !== parterSignUpOriginEnum.SELF_SIGNUP
      ) {
        return false
      }

      const signUpOnboardingAlreadyShownToUser = await dispatch(
        'signUpOnboardingAlreadyShownToUser'
      )

      return !signUpOnboardingAlreadyShownToUser
    },
    async handleUsuarioLogadoRedirect({ dispatch }) {
      setRefreshTokenTimer();

      if (await dispatch('shouldRedirectToSelfSignUpOnboarding')) {
        return router.push({
          name: 'self-sign-up-bag-management'
        })
      }
      router.push({ name: 'main-board' })
    }
  },
  modules: {
    selfSignUp: selfSignUpModule
  }
})
